import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./designs.css"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import pic1 from './images/designs/design1.png';
import pic2 from './images/designs/design2.png';
import pic3 from './images/designs/design3.png';
import pic4 from './images/designs/design4.png';
import pic5 from './images/designs/design5.png';
import Carousel from 'react-bootstrap/Carousel';


function Designs() {
  return (
    <div className="slider" id="slider">
        <h1 className="designhead">Have a look at my Sample Designs</h1>
        <Carousel className="carousal">
        <Carousel.Item>
          <img
            className="d-block designpic "
            src={pic1}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block designpic "
            src={pic2}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block designpic "
            src={pic3}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block designpic "
            src={pic4}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block designpic "
            src={pic5}
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Designs;
