import React, {useState, useEffect} from "react";
//import {uuid} from "uuidv4";
import { v4 } from "uuid";
import './contacts.css';
import Header from "./Header";
import AddContact from "./AddContact";
import ContactList from "./ContactList";
import {useNavigate, useParams, useLocation} from 'react-router-dom';


function Contacts() {
    let navigate = useNavigate();
const LOCAL_STORAGE_KEY = "contacts";

const retriveContacts = localStorage.getItem(LOCAL_STORAGE_KEY) ?JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) :[];
const [contacts, setContacts] = useState(retriveContacts);

const addContactHandler = (contact) => {
  console.log(contact);
  setContacts([...contacts, {id: v4, ...contact}]);
};

const removeContactHandler = (id) => {
  const newContactList = contacts.filter((contact) => {
    return contact.id !== id;
  });
  setContacts(newContactList);
};

useEffect(() => {
const retriveContacts = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
if (retriveContacts) setContacts(retriveContacts);
}, []);

useEffect(() =>{
localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(contacts));
}, [contacts]);

  return (
<div className="contacts">
<Header />
<AddContact addContactHandler={addContactHandler}/>
<ContactList contacts={contacts} getContactId = {removeContactHandler}/>
  <button className="btn-lg btn-dark contactbutt"onClick={()=>navigate("/applications")}>Go back</button>

  <footer className="contact-head">
  <h1>  With Love ❤️ From Keerti </h1>
  </footer>
  </div>
  );
};

export default Contacts;
