import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./wallpaper.css";
import "./kiki.css";
import laptop from "./images/laptop5.jpg";

function Wallpaper() {
  return (
    <div className="wall">
      <h1 className="kiki"> Welcome to Kiki Apps </h1>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <h1 className="web">
            <b>GET CUSTOMISED WEBSITES</b>
          </h1>
          <p className="main">WITH LIFETIME MAINTENANCE</p>
          <div className="row">
          <div className="contact col-6">
            <a href="tel:08660321419">
              <button className="butt" style={{float: "right"}}>
                <b>CALL ME</b>
              </button>
            </a>
          </div>
          <div className="contact col-6">
            <a href="https://wa.me/08660321419?text=Hey%20Kiki-Apps.%20I'm%20enquiring%20about%20website%20development.%20Please%20explain%20in%20detail.">
              <button className="butt" style={{float: "left"}}>
                <b>WHATSAPP</b>
              </button>
            </a>
          </div>
          </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <img className="wall-img" src={laptop} />
          </div>

        
      </div>
    </div>
  );
}

export default Wallpaper;
