import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "./weather.css";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import {useNavigate, useParams, useLocation} from 'react-router-dom';



const Weather = () => {
    let navigate = useNavigate();

  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [weatherData, setWeatherData] = useState(null);


  const now = new Date();
const currentTime = now.toLocaleTimeString();
console.log(currentTime);


console.log(weatherData);

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const API_KEY = "dc0e58fb523621592a54e95348ca4002"

    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?q=${city},${country}&appid=${API_KEY}&units=metric`
    );
    setWeatherData(response.data);
  };

var sunrise = null;
var sunset = null;
var sunrisemain = null;
var sunsetmain = null;
var visibility = null;
var image = null;

if(weatherData) {
sunrise = weatherData.sys.sunrise;
sunset= weatherData.sys.sunset;
const sunriseDate = new Date(sunrise * 1000);
const sunsetDate = new Date(sunset * 1000)
const sunriseString = sunriseDate.toLocaleString();
const sunsetString = sunsetDate.toLocaleString();
const sunriseindex = sunriseString.indexOf(",");
const sunsetindex = sunsetString.indexOf(",");
sunrisemain = sunriseString.slice(sunriseindex+1, sunriseString.length);
sunsetmain = sunsetString.slice(sunsetindex+1, sunsetString.length);
visibility = weatherData.visibility/1000;
image = weatherData.weather[0].main
}

console.log(image)

  if (!weatherData) {
    return (
      <div>
        <form onSubmit={handleSubmit}>
        <div className="container-weather">
        <div className="row">
        <div className="col-5">
          <input className="city-weather" type="text" placeholder="City" onChange={handleCityChange} />
       </div>
       <div className="col-5">
          <input type="text" className="country-weather" placeholder="Country" onChange={handleCountryChange} />
       </div>
       <div className="col-2">
          <button className="submit-weather" type="submit">Submit</button>
       </div>
          </div>
          </div>
        </form>
        <button className="btn btn-dark btn-lg drumbutt"onClick={()=>navigate("/applications")}>Go back</button>

      </div>
    );
  }

  return (
    <div>
        <button className="btn btn-dark btn-lg drumbutt"onClick={()=>navigate("/applications")} style={{marginTop:"5%"}}>Go back</button>

    <div className="weather">
    <h1 className="heading-weather">Weather App</h1>

    <form onSubmit={handleSubmit}>
    <div className="container-weather">
    <div className="row">
    <div className="col-5">
      <input className="city-weather" type="text" placeholder="City" onChange={handleCityChange} />
   </div>
   <div className="col-5">
      <input type="text" className="country-weather" placeholder="Country" onChange={handleCountryChange} />
   </div>
   <div className="col-2">
      <button className="submit-weather" type="submit">Submit</button>
      </div>
      </div>
      </div>
    </form>

      <div className="box-weather">
      <h1>{weatherData.name}, {weatherData.sys.country}. Weather</h1>
      <p>As of {currentTime}</p>
      <h1 className="temp-weather">{weatherData.main.temp}°</h1>
      <p>{weatherData.weather[0].main}</p>
      </div>

      <div className="data-weather container-weather">
      <div className="rowss-weather row">

      <div className="span1-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6 bold-weather"><span>High/Low</span></div>
      <div className="col-6 not-bold-weather"><span>{weatherData.main.temp_max}/{weatherData.main.temp_min}</span></div>
      </div>
      </div>
      </div>

      <div className="span2-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6 bold-weather"><span>Wind</span></div>
      <div className="col-6 not-bold-weather"><span>{weatherData.wind.speed} km/hr</span></div>
      </div>
      </div>
      </div>
      </div>

      <div className="rowss-weather row">
      <div className="span1-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6 bold-weather"><span>Humidity</span></div>
      <div className="col-6 not-bold-weather"><span>{weatherData.main.humidity} %</span></div>
      </div>
      </div>
      </div>

      <div className="span2-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-8  bold-weather"><span>Wind Direction</span></div>
      <div className="col-4 not-bold-weather"><span>{weatherData.wind.deg}° deg</span>
      </div>
      </div>
      </div>
      </div>

      <div className="rowss-weather row extra-weather">
      <div className="span1-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6 bold-weather"><span>Pressure</span></div>
      <div className="col-6 not-bold-weather"><span>{weatherData.main.pressure} hPa</span></div>
      </div>
      </div>
      </div>

      <div className="span2-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6  bold-weather"><span>Sunrise</span></div>
      <div className="col-6 not-bold-weather"><span>{sunrisemain}</span></div>
      </div>
      </div>
      </div>
      </div>

      <div className="rowss-weather row">
      <div className="span1-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6  bold-weather"><span>Visibility</span></div>
      <div className="col-6 not-bold-weather"><span>{visibility} Km</span></div>
      </div>
      </div>
      </div>

      <div className="span2-weather col-5">
      <div className="container-weather">
      <div className="row">
      <div className="col-6  bold-weather"><span>Sunset</span></div>
      <div className="col-6 not-bold-weather"><span>{sunsetmain}</span></div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>


</div>


  );
};

export default Weather;
