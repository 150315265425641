import React from "react";
import user from "../images/user1.png";

const ContactCard = (props) => {
const{id, name, email} = props.contact;

  return (<div className="card">
    <div className="carditem">
    <img className="cardimg" src={user} alt="user" />
    <div className="">
    <div className="">{name}</div>
    <div>{email}</div>
    </div>
   </div>
    </div>
  );
};

export default ContactCard;
