import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./me.css";
import me from "./images/keerti6.png";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import web from "./images/web.jpg";
import code from "./images/code.jpg";
import Graphic from "./images/graphic.jpg";
import coding from "./images/coding.jpg";
import hosting from "./images/hosting.jpg";
import {Link} from 'react-router-dom';
import About1 from "./about1";


function Me() {
  return (

   <div>
    

    <div className="row about1">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img src={me} className="keerti" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 me-text">
          <p className="behind"> Behind The Websites </p>
          <h1 className="about"> About Me </h1>
          <p className="cheers">
            I'm a Software Engineer & freelancer who loves to build and design
            Websites. Get your own website for a very minimal price range.
            Showcase your business online or sell your products online. Logo
            design, website design, content writing, hosting, everything is on
            me. Contact me for more details.
            <br />
          </p>
          <p className="cheers2">Cheers!</p>
        </div>
      </div>


    <div className="me">
      <div className="row" style={{ paddingTop: "5%" }}>
        <div className="col-lg-6 col-md-6 col-12">
          <img
            src={web}
            style={{ width: "60%", borderStyle: "solid", borderColor: "white" }}
          />
          <img
            src={code}
            style={{
              width: "40%",
              position: "relative",
              left: "-30px",
              bottom: "-10%",
              borderStyle: "solid",
              borderColor: "white",
            }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-12 expertise container">
          <h1 style={{ color: "white", textAlign: "center" }}>MY EXPERTISE</h1>
          <h5 className="expert-head">A Complete Website Builder</h5>
          <p className="expert-para">
            I have been designing, developing and hosting websites from more
            than 3 years. I have worked with more than 10 clients till now .
            While being a designer and developer is a difficult tug-of-war
            between my inner dreamer and critic, I've grown to love this process
            a lot. It hasn't just taught me much about the website world, but
            also helping various kinds of businesses.
          </p>
          <Link to="/samples" className="navlink"><button className="butt-me">
            <h1 className="butt-h1 link">See Previous Work</h1>
          </button></Link>
          <Link to="/applications" className="navlink"><button className="butt-me">
            <h1 className="butt-h1 link">See My Applications</h1>
          </button></Link>
        </div>
        <hr className="line"/>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 expert-para">
            <h1 className="expert-head-2">Graphic & Logo<br /> Designing</h1>
            <h6 className=""> I use professional designing tools like Figma and Canva. Reach me out if you want to get graphic or logo designs for your business. For website package, website designing is also included. All the content of website, or graphics is on me.</h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={Graphic}  style={{width:"100%", marginTop:"5%"}}/>
          </div> 
          <hr className="line"/>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={coding}  style={{width:"100%", marginTop:"5%"}}/>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 expert-para">
            <h1 className="expert-head-2">Coding and<br/> Development</h1>
            <h6 className="">I build fully responsive websites according to the customer's requirement. Everything is customised. I code using JavaScript and React. For UI and UX I use Bootstrap, CSS, Animations. I also use some other libraries according to the requirements.</h6>
          </div>
          <hr className="line"/>
          <div className="col-lg-6 col-md-6 col-sm-12 expert-para">
            <h1 className="expert-head-2">Website Hosting &<br /> Security</h1>
            <h6 className=""> I buy domain according to the type of website and host the website on the server taking care of the backup, databases and security. I use goDaddy cPanel for hosting and SSL certificates. </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={hosting}  style={{width:"100%", marginTop:"5%"}}/>
          </div> 
        </div>
      </div>
      </div>
      </div>
    
  );
}

export default Me;
