import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./home.css"
import Navbar from "./navbar.js"
import Wallpaper from "./wallpaper.js"
import Me from "./me.js"
import Price from "./price.js"
import Designs from "./designs.js"
import Foot from "./foot.js";
import WhyWebsite from './whyWebsite.js';


function Home() {
  return (
    <div className="home">
    <Navbar />
    <Wallpaper />
        <Me />
    <Price />
    <Foot />
    </div>
  );
}

export default Home;
