import "./me.css"
import me from "./images/keerti6.png"
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./price.css"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import React from 'react';
import pic1 from './images/basic-1.jpeg';
import pic2 from './images/basic-2.jpeg';
import pic3 from './images/basic-3.jpeg';
import Card from 'react-bootstrap/Card';
import presence from "./images/presence.gif";
import profession from "./images/profession.gif";
import marketing from "./images/marketing.gif";
import customer from "./images/customer.gif";
import global from "./images/global.gif";
import advert from "./images/advert.gif";
import engage from "./images/engage.gif";
import data from "./images/data.gif";
import compete from "./images/compete.gif";
import NavbarApp from './navbar';
import Foot from './foot.js';

function WhyWebsite() {

  return (
    <div className='home'>
        <NavbarApp />
  
    <div className="why row">
        <h1 style={{color: 'white', textAlign: 'center', marginBottom: '5%'}}>Why Website is important?</h1>
        <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={presence} />
    <Card.Body>
      <Card.Title className="card-head">Online Presence</Card.Title>
      <Card.Text className="card-text1 grey">
      A website provides a digital presence for your business, making it accessible to a global audience 24/7. It acts as a virtual storefront, allowing potential customers to find information about your products or services at any time.
      </Card.Text>
    </Card.Body>
  </Card>
  </div>

  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={profession} />
    <Card.Body>
      <Card.Title className="card-head">Professionalism</Card.Title>
      <Card.Text className="card-text1 grey">
      A well-designed and professional-looking website can enhance the credibility of your business. In today's digital age, consumers often expect legitimate businesses to have an online presence. A website lends an air of professionalism and legitimacy to your brand.      </Card.Text>
    </Card.Body>
  </Card>
  </div>
  

  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={marketing} />
    <Card.Body>
      <Card.Title className="card-head">Marketing & Branding</Card.Title>
      <Card.Text className="card-text1 grey">
      A website serves as a powerful marketing tool. You can showcase your products or services, highlight customer testimonials, and communicate your brand's values. It also provides an opportunity to integrate various marketing strategies, such as content marketing, social media integration, and search engine optimization (SEO).   
      </Card.Text> 
      </Card.Body>
  </Card>
  </div>

  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={customer} />
    <Card.Body>
      <Card.Title className="card-head">Customer Convenience</Card.Title>
      <Card.Text className="card-text1 grey">
      Customers appreciate the convenience of finding information about a business online. A website allows them to browse products, read reviews, and make purchases from the comfort of their homes. This convenience can lead to increased customer satisfaction and loyalty.      </Card.Text> 
      </Card.Body>
  </Card>
  </div>

  
  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={global} />
    <Card.Body>
      <Card.Title className="card-head">Global Reach</Card.Title>
      <Card.Text className="card-text1 grey">
      With a website, your business can reach a global audience. Whether you operate locally or internationally, having an online presence allows you to expand your reach and attract customers from different geographical locations.
      </Card.Text>      </Card.Body>
  </Card>
  </div>


  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={advert} />
    <Card.Body>
      <Card.Title className="card-head">Cost-Effective Advertising</Card.Title>
      <Card.Text className="card-text1 grey">
      Compared to traditional forms of advertising, such as print or TV ads, a website is a cost-effective way to promote your business. Online marketing strategies like content creation, social media, and email marketing can be more budget-friendly and often have a broader reach      </Card.Text>      </Card.Body>
  </Card>
  </div>
       
  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={engage} />
    <Card.Body>
      <Card.Title className="card-head">Customer Engagement</Card.Title>
      <Card.Text className="card-text1 grey">
      A website provides a platform for direct communication with your customers. Features like contact forms, live chat, and feedback forms allow customers to interact with your business, ask questions, and provide valuable feedback.   </Card.Text>      </Card.Body>
  </Card>
  </div>

  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={data} />
    <Card.Body>
      <Card.Title className="card-head">Data Collection & Analysis</Card.Title>
      <Card.Text className="card-text1 grey">
      Through website analytics, businesses can gather valuable data about their online visitors. This information can be used to understand customer behavior, preferences, and demographics, helping businesses make informed decisions and improve their products or services.   </Card.Text>      </Card.Body>
  </Card>
  </div>

  <div className="col-lg-4 col-md-4 col-12">
  <Card className="why-card" >
  <Card.Img variant="top" className="why-card-img" src={compete} />
    <Card.Body>
      <Card.Title className="card-head">Competitive Edge</Card.Title>
      <Card.Text className="card-text1 grey">
      Many of your competitors are likely to have an online presence. Having a website ensures that you remain competitive in the market. It allows you to showcase your unique selling points and stay relevant in the minds of your target audience.   </Card.Text>      </Card.Body>
  </Card>
  </div>
    
    </div>
  
    <Foot />
    </div>
  );
}

export default WhyWebsite;
