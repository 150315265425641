import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';
import calculator from "./calculator.png"
import contacts from "./contacts.png"
import photos from "./photos.jpg"
import quiz from "./quiz.jfif"
import recipe from "./recipe.png"
import todolist from "./todolist.png"
import dice from "./dice.jpg";
import drum from "./drum.jpg";
import cloud from "./cloud3.png";
import photo from "./profile.png";
import mountain from "./mountain.png";
import "./home.css"
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Foot from '../foot.js';
import NavbarApp from '../navbar';
import weather from '../images/weather.png'

function Home() {
  let navigate = useNavigate();
  return (
<div>
  <NavbarApp />
<div className="end">
<h1>My Applications</h1>
</div>
<div className="apps row">
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/calculator"><img class="thumbnail" src={calculator} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/Contacts"><img class="thumbnail" src={contacts} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/quiz"><img class="thumbnail" src={quiz} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/recipe"><img class="thumbnail" src={recipe} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/dice"><img class="thumbnail" src={dice} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/drum"><img class="thumbnail" src={drum} /></Link>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 col-6">
<Link to="/applications/weather"><img class="thumbnail" src={weather} /></Link>
</div>
</div>
<div className="homeback">
<button className="btn btn-dark btn-lg homebutt"onClick={()=>navigate("/")}>Go back</button>
</div>
<footer>
<div class="end">

<h1>With Love ❤️ From Kiki Apps</h1>

</div>
</footer>
<Foot />
</div>

  );
}

export default Home;
