import React from "react";


const Header = () => {
  return  (
    <div className="contact-head">
    <h2>Contact Manager</h2>
    </div>
  );
};

export default Header;
