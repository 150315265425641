import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import './recipe.css';


const Recipe = () => {
  let navigate = useNavigate();
  const APP_ID = "21cbb773";
  const APP_KEY = "1b7201eeddc6ce0334cb48d89647d6fc"
const [recipes, setRecipes] = useState([]);
const [search, setSearch] = useState("");
const [query, setQuery] = useState('chicken');

 useEffect( () => {
getRecipes();
}, [query]);

 const getRecipes = async () => {
   const response = await fetch(`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${APP_KEY}`)
const data = await response.json();
setRecipes(data.hits);
};

 const updateSearch = (e) => {
   setSearch(e.target.value);
 };

 const getSearch = (e)=> {
   e.preventDefault();
   setQuery(search);
   setSearch("");
 };

  return (
    <div className="recipe">
    <div >
    <form className="search-form" onSubmit={getSearch}>
    <input className="search" type="text" placeholder="search for recipe" value={search} onChange={updateSearch}/>
    <button className="btn btn-lg btn-dark" type="submit">Search
    </button>
    <button className="btn btn-dark btn-lg"onClick={()=>navigate("/applications")}>Go back</button>
    </form>
    </div>
    {recipes && recipes.map((recipe, index)=> (
      <div className="recipe-box" key={index}>
      <h3>Name: {recipe.recipe.label}</h3>
      <h3>Calories: {recipe.recipe.calories} Cal</h3>
      <img className="recipeimg" src={recipe.recipe.image} />
      <h3> Ingredients: </h3>
      {recipe.recipe.ingredients.map((ingredient, id) => (
        <span key={id}>{ingredient.text}<br/></span>))}
      </div>))}
      </div>
  );
}

export default Recipe;
