import React, {useState} from "react";
import "./samples.css"
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import coorg from "../images/coorg.jpg";
import Designs from "../designs.js";
import NavbarApp from "../navbar.js";
import Foot from "../foot.js";
import backstreet from "../images/backstreet.jpg";
import Card from 'react-bootstrap/Card';
import moktama from '../images/moktama.jpg';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap.css";
import knockin from "../images/knockin.jpg"

function Samples() {
    let navigate = useNavigate();

  return (<div className="" style={{backgroundColor:'black'}}>
    <NavbarApp />
  <h1 className="samplehead">PREVIOUS WORK WITH CLIENTS</h1>
  <div className="row  align-items-center text-center">
  <div className="col-lg-6 col-md-6 col-12">
  <Card className="client-card cards" >
  <Card.Img variant="top" className="client-card-img" src={coorg} />
    <Card.Body>
      <Card.Title className="client-card-head">The Coorg Travels</Card.Title>
      <Card.Text className="card-text">
      A Travel Agency In Coorg  </Card.Text>  
      <a href="http://the-coorg-travels.surge.sh"><button className="open">Open Website</button> </a>
          </Card.Body>
  </Card>
  </div>
  <div className="col-lg-6 col-md-6 col-12">
  <Card className="client-card cards" >
  <Card.Img variant="top" className="client-card-img" src={backstreet} />
    <Card.Body>
      <Card.Title className="client-card-head">Backstreet Properties</Card.Title>
      <Card.Text className="">
      A homestay in Goa  </Card.Text> 
      <a href="http://backstreet-properties.com"><button className="open">Open Website</button> </a>    </Card.Body>
  </Card>
  </div>
  <div className="col-lg-6 col-md-6 col-12">
  <Card className="client-card cards" >
  <Card.Img variant="top" className="client-card-img" src={moktama} />
    <Card.Body>
      <Card.Title className="client-card-head">Moktama IT solutions</Card.Title>
      <Card.Text className="card-text">
      A IT Solutions company in USA  </Card.Text>  
      <a href="https://moktama.com"><button className="open">Open Website</button> </a>
          </Card.Body>
  </Card>
  </div>

  <div className="col-lg-6 col-md-6 col-12">
  <Card className="client-card cards" >
  <Card.Img variant="top" className="client-card-img" src={knockin} />
    <Card.Body>
      <Card.Title className="client-card-head">KnockIn Enterprises</Card.Title>
      <Card.Text className="card-text">
      A Real Estate Agency In Bangalore </Card.Text>  
      <a href="http://knockin-enterprises.surge.sh"><button className="open">Open Website</button> </a>
          </Card.Body>
  </Card>
  </div>
  
  </div>
  <h1 className="more"> More work in development </h1>
  <hr className="line" />
  <Designs />
<Foot />

  </div>
);
}

export default Samples;
