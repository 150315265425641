import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useEffect, useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import {Link} from 'react-router-dom';
import "./navbar.css"
import Apps from "./home/home.js";
import logo from "./images/logo.png"



function NavbarApp() {



  return (
    <div className="navbarmain">
    <Navbar variant="dark" className= "navbarhead navbar-mid " expand="lg" >
      <Container>
        <Navbar.Brand className= "logo link" href=""><Link to="/" className='link'><b><i className="fa-light fa-laptop-mobile "></i><img src={logo} style={{height:"40px"}}/></b></Link></Navbar.Brand>
        <Navbar.Toggle className="drop" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" bg="dark" variant="dark">
          <Nav className="me-auto" className="drop">
            <Nav.Link className= "navbar-text" href=""><Link className="navlink" to="/samples"><b>Client Work</b></Link></Nav.Link>
            <Nav.Link className= "navbar-text" href=""><Link className="navlink" to="/applications"><b>Applications</b></Link></Nav.Link>
            <Nav.Link className= "navbar-text" href=""><Link className="navlink" to="/benefits"><b>Benefits</b></Link></Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </div>
  );
}

export default NavbarApp;
