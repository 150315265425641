import React, {useEffect, useState} from "react";
import dice1 from "./images/dice1.png";
import dice2 from "./images/dice2.png";
import dice3 from "./images/dice3.png";
import dice4 from "./images/dice4.png";
import dice5 from "./images/dice5.png";
import dice6 from "./images/dice6.png";
import "./dice.css"
import {useNavigate, useParams, useLocation} from 'react-router-dom';

function Dice() {
  let navigate = useNavigate();
const [started, setStarted] = useState(false);

var randomNumber1 = Math.floor(Math.random()*6 ) + 1;
var randomNumber2 = Math.floor(Math.random()*6) + 1;
if(randomNumber1==1)
var img1=dice1;
if(randomNumber1==2)
img1=dice2;
if(randomNumber1==3)
img1=dice3;
if(randomNumber1==4)
img1=dice4;
if(randomNumber1==5)
img1=dice5;
if(randomNumber1==6)
img1=dice6;
if(randomNumber2==1)
var img2=dice1;
if(randomNumber2==2)
img2=dice2;
if(randomNumber2==3)
img2=dice3;
if(randomNumber2==4)
img2=dice4;
if(randomNumber2==5)
img2=dice5;
if(randomNumber2==6)
img2=dice6;

var result = "";
if(randomNumber1>randomNumber2) {
result =  "🚩 Play 1 Wins!";
}
else if(randomNumber1<randomNumber2) {
  result = "🚩 Play 2 Wins!";
}
else {
  result = "Draw!";
}


var head = "Roll the dice"
const changeState= ()=> {
  if(started==true)
  alert("Please Restart The Game")
  setStarted(true);
}
const restart = ()=> {
  setStarted(false)
}



return (
  <div className="dice-container">
  <div className="">
      {started==true ?<h1 className="dice-head">{result}</h1> : <h1 className="dice-head">{head}</h1>}
      <button className="startbutt" onClick={restart}>Restart</button>
      <button className="startbutt" onClick={changeState}>Click to roll</button>
      <div className="dice col-lg=6 md-6 sm-12">
        <p className="dice-p">Player 1</p>
      {started==true ?  <img className="img2" src={img1} /> :  <img className="img2" src={dice6} />}
      </div>
      <div className="dice col-lg=6 md-6 sm-12">
        <p className="dice-p">Player 2</p>
        {started==true ?  <img className="img2" src={img2} /> :  <img className="img2" src={dice6} />}
      </div>

    </div>
      <button className="btn btn-dark btn-lg dicebutt"onClick={()=>navigate("/applications")}>Go back</button>
  <footer className="dice-foot">
    www 🎲 kiki apps 🎲 com
  </footer></div>
)
}

export default Dice;
