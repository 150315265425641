import React from "react";
import "./contacts.css";

class AddContact extends React.Component {
state = {
  name: "",
  email: "",
};

add = (e) => {
  e.preventDefault();
  if(this.state.name === "" || this.state.email === "") {
    alert("All the fields are mandatory!");
    return;
  }
  this.props.addContactHandler(this.state);
  this.setState({name: "", email: ""});
};

render() {
  return(
    <div className="addcontact">
    <h2 className="addhead"><b>Add Contact</b></h2>
    <form className="addform" onSubmit={this.add}>
    <div className="">
    <label className="addtext"><b>Name</b></label>
    <input type="text" name="name" className="" placeholder="Name" value={this.state.name} onChange={(e)=>{this.setState({name: e.target.value})} }/>
    </div>
    <div className="">
    <label className="addtext"><b>Email</b></label>
    <input type="text" name="email" placeholder="Email ID" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
    </div>
    <button className="addbutt">Add</button>
    </form>
    </div>
  );
}
}

export default AddContact;
