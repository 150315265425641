import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./price.css"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import pic1 from './images/basic-1.jpeg';
import pic2 from './images/basic-2.jpeg';
import pic3 from './images/basic-3.jpeg';


function Price() {
  return (
    <div className="price">
    <h1 className="pricing"><b>Pricing Available</b></h1>
    <div className="row">
    <div className="col-lg-4 col-md-4 col-sm-12 tag">
    <h2 className="pricetext1">Basic</h2>
    <span className="hide1"><h4 className="hidehead"><b>Starts from ₹6000/-</b></h4><p className="hidetext">Website with basic design</p><p className="hidetext">Call and email features</p><p className="hidetext">Showcases all your products</p><p className="hidetext">Online booking but offline payment</p><p className="hidetext">No animations and databases</p></span>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-12 tag">
    <h2 className="pricetext2">Moderate</h2>
    <span className="hide2"><h4 className="hidehead"><b>Starts from ₹12000/-</b></h4><p className="hidetext">Website with creative design</p><p className="hidetext">Call, email and whatsapp features</p><p className="hidetext">Showcases all your products</p><p className="hidetext">Online booking but offline payment</p><p className="hidetext">Animations added but no databases</p></span>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-12 tag">
    <h2 className="pricetext3">Advanced</h2>
    <span className="hide3"><h4 className="hidehead"><b>Starts from ₹16000/-</b></h4><p className="hidetext">Website with advanced creative design</p><p className="hidetext">Call, email whatsapp and SMS features</p><p className="hidetext">Showcases all your products</p><p className="hidetext">Online booking with online payment</p><p className="hidetext">Animations and databases available</p></span>
    </div>
    </div>





    
    </div>
  );
}

export default Price;
