import "./drum.css";
import crashPic from "./images/crash.png";
import kickPic from "./images/kick.png";
import snarePic from "./images/snare.png";
import tom1Pic from "./images/tom1.png";
import tom2Pic from "./images/tom2.png";
import tom3Pic from "./images/tom3.png";
import tom4Pic from "./images/tom4.png";
import crashAudio from "./sounds/crash.mp3";
import kickAudio from "./sounds/kick-bass.mp3";
import snareAudio from "./sounds/snare.mp3";
import tom1Audio from "./sounds/tom-1.mp3";
import tom2Audio from "./sounds/tom-2.mp3";
import tom3Audio from "./sounds/tom-3.mp3";
import tom4Audio from "./sounds/tom-4.mp3";
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';

function Drum() {
let navigate = useNavigate();
const audio1 = new Audio();
audio1.src = crashAudio;
const audio2 = new Audio();
audio2.src = kickAudio;
const audio3 = new Audio();
audio3.src = snareAudio;
const audio4 = new Audio();
audio4.src = tom1Audio
const audio5 = new Audio();
audio5.src = tom2Audio;
const audio6 = new Audio();
audio6.src = tom3Audio;
const audio7 = new Audio();
audio7.src = tom4Audio;

function keySound(e) {
if(e.key==="w"){
    audio1.play();
    }
else if(e.key==="a")
      audio2.play();
else if(e.key==="s")
      audio3.play();
else if(e.key==="d")
      audio4.play();
else if(e.key==="j")
      audio5.play();
else if(e.key==="k")
      audio6.play();
else if(e.key==="l")
      audio7.play();
  }

  function mouseSound(e) {
    if(e.target.value=="1")
        audio1.play();
    else if(e.target.value=="2")
          audio2.play();
    else if(e.target.value=="3")
          audio3.play();
    else if(e.target.value=="4")
          audio4.play();
    else if(e.target.value=="5")
          audio5.play();
    else if(e.target.value=="6")
          audio6.play();
    else if(e.target.value=="7")
          audio7.play();

  }





  return (
    <div className="drum-body">
    <h1 className="drum-h1" id="title">Drum 🥁 Kit</h1>
  <div className="set row">
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="1" className="w drum" onClick={mouseSound} onKeyPress={keySound}>w</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="2" className="a drum" onClick={mouseSound} onKeyPress={keySound}>a</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="3" className="s drum" onClick={mouseSound} onKeyPress={keySound}>s</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="4" className="d drum" onClick={mouseSound} onKeyPress={keySound}>d</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="5" className="j drum" onClick={mouseSound} onKeyPress={keySound}>j</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="6" className="k drum" onClick={mouseSound} onKeyPress={keySound}>k</button>
  </div>
  <div className="col-lg-3 col-md-4 col-sm-4 drumcol">
  <button value="7" className="l drum" onClick={mouseSound} onKeyPress={keySound}>l</button>
  </div>
</div>

<button className="btn btn-dark btn-lg drumbutt"onClick={()=>navigate("/applications")}>Go back</button>
<footer className="drum-footer">
  With Love ❤️ From Keerti
</footer>
    </div>
  )
}

export default Drum;
