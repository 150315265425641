import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Calculator from "./calculator/calculator.js"
import Home from "./home.js";
import Applications from "./home/home.js";
import Contacts from "./contacts/components/contacts.js";
import Quiz from "./quiz/quiz.js";
import Recipe from "./recipe/recipe.js";
import Dice from "./diceGame/dice.js";
import Drum from "./drum/drum.js";
import Samples from "./samples/samples.js";
import WhyWebsite from './whyWebsite.js';
import Weather from './weather/weather.js';
import "./App.css"


function App() {
  return (
    <div className='App'>

    <Router>
      <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/samples" element={<Samples />}></Route>
      <Route path="/applications" element={<Applications />}></Route>
      <Route path="/applications/calculator" element={<Calculator />}></Route>
      <Route path="/applications/contacts" element={<Contacts />}></Route>
      <Route path="/applications/quiz/*" element={<Quiz />}></Route>
      <Route path="/applications/weather" element={<Weather />}></Route>

      <Route path="/applications/recipe" element={<Recipe />}></Route>
      <Route path="/applications/dice" element={<Dice />}></Route>
      <Route path="/applications/drum" element={<Drum />}></Route>
      <Route path="/benefits" element={<WhyWebsite />}></Route>
      </Routes>
     </Router>
    </div>
  );
}

export default App;
