import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./foot.css"
import "./kiki.css"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import hand from "./images/Hand1.jpg";


function Foot() {
  return (
    <div className="foot" id="contact">
    <h1 className ="foothead kiki">Let's work together</h1>
    <div className="row">
    <div className="col-lg-6 col-md-6 col-sm-12 footimg">
    <img className="hand" src={hand} />
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12  foottext">
    <h3 className="footcontact">Contact Me</h3>
    <p className="footp">Mobile No: +91 8660321419</p>
    <p className="footp">Email: kreetiraj749@gmail.com</p>
    <p className="footp">Address: Bangalore, Karnataka</p>
    <span className="handle"><a href="https://www.instagram.com/kit.kat_707/" className="linktext"><i class="fa-brands fa-instagram"></i></a><span>                    </span><a href="https://www.facebook.com/profile.php?id=100009542829316" className="linktext"><i class="fa-brands fa-facebook"></i></a><span>                 </span><a href="https://www.linkedin.com/in/keerti-raj-49369818b/" className="linktext"><i class="fa-brands fa-linkedin"></i></a></span>
    </div>
    </div>
    </div>
  );
}

export default Foot;
